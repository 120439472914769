import React from 'react';

import config from '../../../data/SiteConfig';

const url = config.siteRss;

const SocialContact = ({social}) => (
    <ul>
        {social.twitter && (
            <li>
                <a
                  href={`https://twitter.com/${social.twitter}`}
                  className="icon-font"
                >
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
            </li>
        )}
        {social.facebook && (
            <li>
                <a
                  href={`https://facebook.com/${social.facebook}`}
                  className="icon-font"
                >
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
            </li>
        )}
        {social.github && (
            <li>
                <a
                  href={`https://github.com/${social.github}`}
                  className="icon-font"
                >
                  <i className="fa fa-github" aria-hidden="true" />
                </a>
            </li>
        )}
        {social.linkedin && (
            <li>
                <a
                  href={`https://linkedin.com/in/${social.linkedin}`}
                  className="icon-font"
                >
                  <i className="fa fa-linkedin" aria-hidden="true" />
                </a>
            </li>
        )}
        {social.email && (
            <li>
                <a href={`mailto:${social.email}`} className="icon-font">
                  <i className="fa fa-envelope-o" aria-hidden="true" />
                </a>
              </li>
        )}
        <li>
            <a href={url} className="icon-font">
                <i className="fa fa-rss" aria-hidden="true" />
            </a>
        </li>
    </ul>
);

export default SocialContact;