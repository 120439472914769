const config = {
    title: "Gobinda's Blogs",
    siteTitle: "gobindathakur.com", // Site title.
    siteTitleShort: "GT Blog", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "Gobinda's Blogging Site", // Alternative site title for SEO.
    siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
    siteUrl: "https://www.gobindathakur.com", // Domain of your website without pathPrefix.
    pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
    fixedFooter: false, // Whether the footer component is fixed, i.e. always visible
    siteDescription: "My blogs on various topics.", // Website description used for RSS feeds/meta description tag.
    siteRss: "/rss.xml", // Path to the RSS file.
    siteFBAppID: "1638712979592231", // FB Application ID for using app insights
    siteGATrackingID: "UA-84543261-1", // Tracking code ID for google analytics.
    crispChatId: "ed343cfd-4711-4a17-99a0-00b842c4ab8f",
    disqusShortname: "gobindathakur-com", // Disqus shortname.
    postDefaultCategoryID: "Tech", // Default category for posts.
    dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
    dateFormat: "DD/MM/YYYY", // Date format for display.
    userName: "Gobinda Thakur", // Username to display in the author segment.
    authorRole: "Full-stack Developer",
    userEmail: "sgoinda007@gmail.com", // Email used for RSS feed's author segment
    userTwitter: "gobinda_thakur", // Optionally renders "Follow Me" in the UserInfo segment.
    userGithub: "go-gyan",
    userLinkedIn: "gobinda-thakur-87688752",
    userLocation: "Bhubaneswar, Odisha", // User location to display in the author segment.
    userAvatar: "https://api.adorable.io/avatars/150/my-avtar.png", // User avatar to display in the author segment.
    userDescription:
      "I am a software developer by profession. I like to spend time in learning and writting. I learn for myself and write for others. I remind myself every morning: Nothing I say this day will teach me anything. So if I'm going to learn, I must do it by listening and observing.", // User description to display in the author segment.
    // Links to social profiles/projects you want to display in the author segment/navigation bar.
    userLinks: [
      {
        label: "GitHub",
        url: "https://github.com/GO-gyan",
        iconClassName: "fa fa-github"
      },
      {
        label: "LinkedIn",
        url: "https://www.linkedin.com/in/gobinda-thakur-87688752/",
        iconClassName: "fa fa-linkedin"
      },
      {
        label: "Twitter",
        url: "https://twitter.com/Gobinda_Thakur",
        iconClassName: "fa fa-twitter"
      },
      {
        label: "Email",
        url: "mailto:sgobinda007@gmail.com",
        iconClassName: "fa fa-envelope"
      }
    ],
    copyright: "Copyright © 2019. GT" // Copyright string for the footer of the website and RSS feed.
  };
  
  // Validate
  
  // Make sure pathPrefix is empty if not needed
  if (config.pathPrefix === "/") {
    config.pathPrefix = "";
  } else {
    // Make sure pathPrefix only contains the first forward slash
    config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
  }
  
  // Make sure siteUrl doesn't have an ending forward slash
  if (config.siteUrl.substr(-1) === "/")
    config.siteUrl = config.siteUrl.slice(0, -1);
  
  // Make sure siteRss has a starting forward slash
  if (config.siteRss && config.siteRss[0] !== "/")
    config.siteRss = `/${config.siteRss}`;
  
module.exports = config;
