import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Sidebar from '.././components/sidebar'
import '../styles/main.scss'
import '../styles/fonts/font-awesome/css/font-awesome.min.css'

const DefaultLayout = ({ children }) => {
  // useEffect(() => {
    
  //   return () => {
  //     if(localStorage.theme === 'dark') {
  //       //localStorage.setItem("theme", "light");
  //       document.body.classList.remove('dark');
  //       document.body.classList.add('light');
  //     }
  //   }
  // }, []);

  return (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        all: allMarkdownRemark {
          totalCount
        }
        site {
          siteMetadata {
            author
            authorRole
            description
            social {
              twitter
              facebook
              linkedin
              github
              email
            }
          }
        }
      }
    `}
    render={data => (
      <div
        style={{
          backgroundColor: 'var(--bg)',
          color: 'var(--textNormal)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out',
        }}
      >
      <div className="wrapper">
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato|PT+Serif&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Sidebar siteMetadata={data.site.siteMetadata} count={data.all.totalCount} />
        <div className="content-box clearfix">{children}</div>
      </div>
        <footer className="footer-main" id="header">
          <div className="page-links">
            <Link to="/" activeClassName="active"><span className="menu">Home</span></Link>
            <Link to="/about" activeClassName="active"><span className="menu">About</span></Link>
            <Link to="/tags" activeClassName="active"><span className="menu">Tags</span></Link>
            <Link to="/categories" activeClassName="active"><span className="menu">Categories</span></Link>
          </div>
        </footer>
      </div>
    )}
  />);
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
