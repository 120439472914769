import React, { useState, useEffect } from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

const MyTheme = () => {
  
  const [isDark, setDark] = useState(undefined);

  useEffect(() => {
    setDark(localStorage.theme === 'dark');
  }, []);

  const handleTheme = (e, toggleTheme, theme) => {
    if(theme === 'dark') {
      setDark(false);
    } else {
      setDark(true);
    }
    toggleTheme(theme === 'dark' ? 'light' : 'dark');
  }
    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
        <Toggle
            checked={isDark}
            aria-label='No label tag'
            icons={{
                checked: <i className="fa fa-sun-o" aria-hidden="true"></i>,
                unchecked: <i className="fa fa-moon-o" aria-hidden="true"></i>,
            }}
            onChange={e => handleTheme(e, toggleTheme, theme)} 
        />
        )}
      </ThemeToggler>
    );
}

export default MyTheme;