import { Link } from 'gatsby';
import React from 'react';

import MyTheme from '../components/ThemeToggler';
import Logo from './pic.jpg';
import SocialContact from './SocialContact';

const Sidebar = ({ siteMetadata, count }) => {
  return (
  <>
    <aside className="sidebar"
      style={{
        backgroundColor: 'var(--bg)',
        color: 'var(--textNormal)',
        transition: 'color 0.2s ease-out, background 0.2s ease-out',
      }}
    >
      <header>
        <div className="about">
          <div className="cover-author-image">
            <Link to="/">
              <img src={Logo} alt={siteMetadata.author} />
            </Link>
          </div>
          <div className="author-name">{siteMetadata.author}</div>
          <div className="author-role">{siteMetadata.authorRole}</div>
          <p className="hide-on-mobile">{siteMetadata.description}</p>
          <div className="article-count">
            <p>{count} articles in total</p>
          </div>
          <div className="page-links">
            <Link to="/" activeClassName="active">
              <div className="menu">
                <i className="fa fa-home" aria-hidden="true"></i>
                Home
              </div>
            </Link>
            <Link to="/tags" activeClassName="active">
              <div className="menu">
                <i className="fa fa-tags" aria-hidden="true"></i>
                Tags
              </div>
            </Link>
            <Link to="/categories" activeClassName="active">
              <div className="menu">
                <i className="fa fa-tasks" aria-hidden="true"></i>
                Categories
              </div>
            </Link>
          </div>
          <div>
            <MyTheme />
          </div>
        </div>
      </header>
      <footer className="hide-on-mobile">
        <section className="contact">
          <h3 className="contact-title">Contact me</h3>
          {siteMetadata.social &&
            <SocialContact social={siteMetadata.social} />
          }
        </section>
        <div className="copyright">
          <p>
            {new Date().getFullYear()} &copy; {siteMetadata.author}
          </p>
        </div>
      </footer>
    </aside>
  </>
  );
}

export default Sidebar
